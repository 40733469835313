import {
  call, put, takeLatest, all, takeEvery
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import * as types from './types'
import {
  request, LOPRX_BASE_URL, version
} from '../../api'
import * as commonTypes from '../common/types'
import { objectToParams } from '../../helpers/utils'

function* getListCaseAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }

    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/cases?${objectToParams(action.payload)}`)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
        }
        yield put({ type: types.GET_LIST_CASE_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_LIST_CASE_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_LIST_CASE_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getCaseReportAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/cases?${objectToParams(action.payload.params)}`)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        yield put({ type: types.GET_CASE_REPORT_SUCCESS, payload: { [action.payload.updateKey]: data.total } })
        break
      }

      default: {
        yield [
          put({ type: types.GET_LIST_CASE_FAIL })
        ]
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_LIST_CASE_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getCaseDetailAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/cases/${action.payload.caseId}`)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        yield put({ type: types.GET_CASE_DETAIL_SUCCESS, payload: data })
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_CASE_DETAIL_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_CASE_DETAIL_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateCaseAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/cases/${action.caseId}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}
function* getPartnersAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/partners?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_PARTNERS_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield put({ type: commonTypes.HIDE_LOADING })
      }
    }
  } catch (error) {
    yield put({ type: commonTypes.HIDE_LOADING })
    if (action.callback) {
      action.callback(false, error)
    }
  }
}
function* createActivityAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/cases/${action.id}/activities`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        yield put({ type: types.CREATE_ACTIVITY_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_ACTIVITY_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.CREATE_ACTIVITY_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getActivityAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/cases/${action.id}/activities?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
        }
        yield put({ type: types.GET_ACTIVITY_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_ACTIVITY_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_ACTIVITY_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createCaseAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/cases`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        yield put({ type: commonTypes.HIDE_LOADING })
        break
      }
      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_CASE_MANAGE_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}
function* deleteActivityAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/cases/${action.id}/activities`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        yield put({ type: types.DELETE_ACTIVITY_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.DELETE_ACTIVITY_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.DELETE_ACTIVITY_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getDatesCaseAsync(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/cases/${action.caseId}/metadata?metaKey=${action.metaKey}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
        }
        yield put({ type: types.GET_DATES_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: types.GET_DATES_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_DATES_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getEndDatesCaseAsync(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/cases/${action.caseId}/metadata?metaKey=endDate`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
        }
        yield put({ type: types.GET_END_DATES_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: types.GET_END_DATES_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_END_DATES_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateDatesAsync(action) {
  try {
    // yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/cases/${action.caseId}/metadata`, action.payload.params)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getAssignCaseAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/cases/${action.caseId}/assign?${objectToParams(action.payload)}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_ASSIGN_CASE_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: types.GET_ASSIGN_CASE_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_ASSIGN_CASE_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createAssignCaseAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/cases/${action.id}/assign`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        yield put({ type: types.CREATE_ASSIGN_CASE_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: types.CREATE_ASSIGN_CASE_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.CREATE_ASSIGN_CASE_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* deleteAssignCaseAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/cases/${action.id}/assign`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        yield put({ type: types.DELETE_ASSIGN_CASE_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: types.DELETE_ASSIGN_CASE_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.DELETE_ASSIGN_CASE_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getListCaseReadinessAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/cases/${action.caseId}/readiness`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateListCaseReadinessAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/cases/${action.caseId}/readiness`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }
      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getAttachmentCaseAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/cases/${action.caseId}/attachments`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createAttachmentCaseAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/cases/${action.caseId}/attachments`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

export default function* watchAction() {
  yield all([
    takeEvery(types.GET_LIST_CASE_ASYNC, getListCaseAsync),
    takeLatest(types.GET_CASE_DETAIL_ASYNC, getCaseDetailAsync),
    takeLatest(types.UPDATE_CASE_ASYNC, updateCaseAsync),
    takeLatest(types.GET_PARTNERS_ASYNC, getPartnersAsync),
    takeEvery(types.GET_CASE_REPORT_ASYNC, getCaseReportAsync),
    takeLatest(types.GET_ACTIVITY_ASYNC, getActivityAsync),
    takeLatest(types.DELETE_ACTIVITY_ASYNC, deleteActivityAsync),
    takeLatest(types.CREATE_ACTIVITY_ASYNC, createActivityAsync),
    takeLatest(types.CREATE_CASE_MANAGE_ASYNC, createCaseAsync),
    takeLatest(types.GET_CASE_METADATA, getDatesCaseAsync),
    takeLatest(types.GET_END_DATES_ASYNC, getEndDatesCaseAsync),
    takeEvery(types.UPDATE_CASE_METADATA, updateDatesAsync),
    takeEvery(types.GET_ASSIGN_CASE_ASYNC, getAssignCaseAsync),
    takeLatest(types.CREATE_ASSIGN_CASE_ASYNC, createAssignCaseAsync),
    takeLatest(types.DELETE_ASSIGN_CASE_ASYNC, deleteAssignCaseAsync),
    takeLatest(types.GET_LIST_CASE_READINESS_ASYNC, getListCaseReadinessAsync),
    takeLatest(types.UPDATE_LIST_CASE_READINESS_ASYNC, updateListCaseReadinessAsync),
    takeLatest(types.GET_ATTACHMENT_CASE_ASYNC, getAttachmentCaseAsync),
    takeLatest(types.CREATE_ATTACHMENT_CASE_ASYNC, createAttachmentCaseAsync)
  ])
}
