// const LOPRX_BASE_URL = 'https://api.loprx.com'
const LOPRX_BASE_URL = process.env.REACT_APP_API_URL || ''
const LOPRX_HOMEPAGE_URL = process.env.REACT_APP_HOMEPAGE_URL || ''
const LOPRX_PHARMACY_URL = process.env.REACT_APP_PHARMACY_URL || ''
const LOPRX_ATTORNEY_URL = process.env.REACT_APP_ATTORNEY_URL || ''
const LOPRX_CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY || ''
const LOPRX_GTAG_ID = process.env.REACT_APP_GTAG_ID || ''

const version = 'v1'
const portals = ['attorney', 'pharmacy']
const SOCKET_URL = process.env.REACT_APP_API_URL || ''

export {
  LOPRX_BASE_URL,
  LOPRX_HOMEPAGE_URL,
  LOPRX_PHARMACY_URL,
  LOPRX_ATTORNEY_URL,
  LOPRX_CAPTCHA_SITE_KEY,
  LOPRX_GTAG_ID,
  SOCKET_URL,
  version,
  portals
}
