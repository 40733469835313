import {
  call, put, takeLatest, all
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import * as types from './types'
import {
  request, LOPRX_BASE_URL, version
} from '../../api'
import { parseArrayToArrayObject, objectToParams } from '../../helpers/utils'
import * as commonTypes from '../common/types'

function* getCompanyProfileAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/company`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_COMPANY_PROFILE_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_COMPANY_PROFILE_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_COMPANY_PROFILE_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateCompanyProfileAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/company`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: types.UPDATE_COMPANY_PROFILE_SUCCESS, payload: data }),
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        break
      }

      default: {
        yield [
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getEmployeesAsync(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/employees?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_EMPLOYEES_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_EMPLOYEES_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_EMPLOYEES_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}
function* getMemberRoleAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/employees?roleId=${action.roleId}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_MEMBER_ROLE_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_MEMBER_ROLE_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_MEMBER_ROLE_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createMemberRoleAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/roles/${action.roleId}/employees`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        yield put({ type: types.CREATE_MEMBER_GROUP_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_MEMBER_GROUP_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.CREATE_MEMBER_GROUP_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* deleteMemberRoleAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/roles/${action.roleId}/members`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        yield put({ type: types.DELETE_MEMBER_GROUP_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.DELETE_MEMBER_GROUP_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.DELETE_MEMBER_GROUP_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getEmployeesDetailAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/employees/${action.payload.id}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [put({ type: types.GET_EMPLOYEES_DETAIL_SUCCESS, payload: data })]
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_EMPLOYEES_DETAIL_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_EMPLOYEES_DETAIL_FAIL }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getRolesDetailAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/roles/${action.payload.id}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [put({ type: types.GET_ROLE_DETAIL_SUCCESS, payload: data })]
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_ROLE_DETAIL_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_ROLE_DETAIL_FAIL }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createRoleAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/roles`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (status === responseCode.REQUEST_SUCCESS) {
          if (action.callback) {
            action.callback(true, data)
          }
          yield [
            put({ type: commonTypes.SHOW_POPUP_SUCCESS })
          ]
        } else {
          if (action.callback) {
            action.callback(false, data)
          }
          yield [
            put({ type: types.CREATE_ROLE_SUCCESS, payload: data }),
            put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
          ]
        }
        yield put({ type: commonTypes.HIDE_LOADING })

        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_ROLE_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      if (error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error.data)
      }
    }
  }
}

function* createEmployeesAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/employees`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (status === responseCode.REQUEST_SUCCESS) {
          if (action.callback) {
            action.callback(true, data)
          }
          yield [
            put({ type: commonTypes.SHOW_POPUP_SUCCESS })
          ]
        } else {
          if (action.callback) {
            action.callback(false, data)
          }
          yield [
            put({ type: types.CREATE_EMPLOYEES_SUCCESS, payload: data }),
            put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
          ]
        }
        yield put({ type: commonTypes.HIDE_LOADING })

        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_EMPLOYEES_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      if (error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error.data)
      }
    }
  }
}

function* editEmployeesAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/employees/${action.id}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (status === responseCode.REQUEST_SUCCESS) {
          if (action.callback) {
            action.callback(true, data)
          }
          yield [
            put({ type: commonTypes.SHOW_POPUP_SUCCESS })
          ]
        } else {
          if (action.callback) {
            action.callback(true, data)
          }
          yield [
            put({ type: commonTypes.SHOW_POPUP_SUCCESS })
          ]
        }
        yield put({ type: commonTypes.HIDE_LOADING })

        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.EDIT_EMPLOYEES_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      if (error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error.data)
      }
    }
  }
}

function* editRoleAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/roles/${action.id}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (status === responseCode.REQUEST_SUCCESS) {
          if (action.callback) {
            action.callback(true, data)
          }
          yield [
            put({ type: commonTypes.SHOW_POPUP_SUCCESS })
          ]
        } else {
          if (action.callback) {
            action.callback(true, data)
          }
          yield [
            put({ type: commonTypes.SHOW_POPUP_SUCCESS })
          ]
        }
        yield put({ type: commonTypes.HIDE_LOADING })

        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.EDIT_ROLE_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      if (error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error.data)
      }
    }
  }
}

function* getListRoleAsync(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/roles?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_LIST_ROLE_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_LIST_ROLE_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_LIST_ROLE_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getListTemplateAsync(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/templates?${objectToParams(action.payload)}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_TEMPLATE_SUCCESS, payload: { data, meta } })
        yield put({ type: commonTypes.HIDE_LOADING })

        break
      }

      default: {
        yield put({ type: commonTypes.HIDE_LOADING })

        yield put({ type: types.GET_TEMPLATE_FAIL })
      }
    }
  } catch (error) {
    yield put({ type: commonTypes.HIDE_LOADING })

    yield put({ type: types.GET_TEMPLATE_FAIL })
  }
}
function* getTemplateDetailAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/templates/${action.payload.id}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [put({ type: types.GET_TEMPLATE_DETAIL_SUCCESS, payload: data }), put({ type: commonTypes.HIDE_LOADING })]
        break
      }

      default: {
        yield put({ type: commonTypes.HIDE_LOADING })

        yield put({ type: types.GET_TEMPLATE_DETAIL_FAIL })
      }
    }
  } catch (error) {
    yield put({ type: commonTypes.HIDE_LOADING })

    yield put({ type: types.GET_TEMPLATE_DETAIL_FAIL })
  }
}

function* createTemplateAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/templates`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]

        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      if (error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error.data)
      }
    }
  }
}

function* updateTemplateAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/templates/${action.payload.id}`, action.payload.params)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]

        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_TEMPLATE_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      if (error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error.data)
      }
    }
  }
}

function* getListStateAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/general/location`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        const stateList = parseArrayToArrayObject(data, true)
        yield put({ type: types.GET_STATE_SUCCESS, payload: stateList })
        break
      }

      default: {
        yield put({ type: types.GET_STATE_FAIL })
      }
    }
  } catch (error) {
    yield put({ type: types.GET_STATE_FAIL })
  }
}

function* getCityByStateAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/general/location?state=${action.payload.state}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        const cityList = parseArrayToArrayObject(data)
        yield put({ type: types.GET_CITY_BY_STATE_SUCCESS, payload: cityList })
        break
      }

      default: {
        yield put({ type: types.GET_CITY_BY_STATE_FAIL })
      }
    }
  } catch (error) {
    yield put({ type: types.GET_CITY_BY_STATE_FAIL })
  }
}

function* getZipCodeByStateAndCityAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/general/location?state=${action.payload.state}&city=${action.payload.city}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        yield put({ type: types.GET_ZIP_CODE_BY_STATE_AND_CITY_SUCCESS, payload: data })
        break
      }

      default: {
        yield put({ type: types.GET_ZIP_CODE_BY_STATE_AND_CITY_FAIL })
      }
    }
  } catch (error) {
    yield put({ type: types.GET_ZIP_CODE_BY_STATE_AND_CITY_FAIL })
  }
}

function* getPermissionRoleAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/roles/${action.payload.id}/permissions`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        yield put({ type: types.GET_ROLE_PERMISSiON_SUCCESS, payload: data })
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_ROLE_PERMISSiON_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_ROLE_PERMISSiON_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* editPermissionRoleAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/roles/${action.id}/permissions`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* deletePermissionRoleAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/roles/${action.id}/permissions`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        yield put({ type: types.DELETE_ROLE_PERMISSiON_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: types.DELETE_ROLE_PERMISSiON_FAIL }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.DELETE_ROLE_PERMISSiON_FAIL }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getListGroupAsync(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/groups?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_LIST_GROUP_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_LIST_GROUP_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_LIST_GROUP_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createGroupAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/groups`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (status === responseCode.REQUEST_SUCCESS) {
          if (action.callback) {
            action.callback(true, data)
          }
          yield [
            put({ type: commonTypes.SHOW_POPUP_SUCCESS })
          ]
        } else {
          if (action.callback) {
            action.callback(false, data)
          }
          yield [
            put({ type: types.CREATE_GROUP_SUCCESS, payload: data }),
            put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
          ]
        }
        yield put({ type: commonTypes.HIDE_LOADING })

        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_GROUP_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      if (error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error.data)
      }
    }
  }
}

function* getGroupDetailAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/groups/${action.payload.id}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [put({ type: types.GET_GROUP_DETAIL_SUCCESS, payload: data })]
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_GROUP_DETAIL_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_GROUP_DETAIL_FAIL }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* editGroupAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/groups/${action.groupId}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (status === responseCode.REQUEST_SUCCESS) {
          if (action.callback) {
            action.callback(true, data)
          }
          yield [
            put({ type: commonTypes.SHOW_POPUP_SUCCESS })
          ]
        } else {
          if (action.callback) {
            action.callback(true, data)
          }
          yield [
            put({ type: commonTypes.SHOW_POPUP_SUCCESS })
          ]
        }
        yield put({ type: commonTypes.HIDE_LOADING })

        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.EDIT_GROUP_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      if (error.data) {
        action.callback(false, error.data)
      } else {
        action.callback(false, error.data)
      }
    }
  }
}

function* getMemberGroupAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/employees?groupId=${action.groupId}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_MEMBER_GROUP_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_MEMBER_GROUP_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_MEMBER_GROUP_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createMemberGroupAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/groups/${action.groupId}/members`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        yield put({ type: types.CREATE_MEMBER_GROUP_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_MEMBER_GROUP_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.CREATE_MEMBER_GROUP_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* deleteMemberGroupAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/groups/${action.groupId}/members`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        yield put({ type: types.DELETE_MEMBER_GROUP_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.DELETE_MEMBER_GROUP_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.DELETE_MEMBER_GROUP_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getCompanyMetaDataAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/company/${action.companyId}/metadata?metaKey=${action.metaKey}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_COMPANY_METADATA_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_COMPANY_METADATA_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_COMPANY_METADATA_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateCompanyMetaDataAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/company/${action.companyId}/metadata?metaKey=${action.metaKey}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: types.UPDATE_COMPANY_METADATA_SUCCESS, payload: data }),
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getRequestCaseMetaDatCompany(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/company/${action.companyId}/metadata?metaKey=${action.metaKey}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_REQUEST_CASE_METADATA_COMPANY_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: types.GET_REQUEST_CASE_METADATA_COMPANY_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_REQUEST_CASE_METADATA_COMPANY_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateRequestCaseMetaDatCompany(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/company/${action.companyId}/metadata?metaKey=${action.metaKey}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: types.UPDATE_REQUEST_CASE_METADATA_COMPANY_SUCCESS, payload: data }),
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        break
      }

      default: {
        yield [
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getRequestReadiness(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/settings/request/readiness?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_REQUEST_READINESS_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_REQUEST_READINESS_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createRequestReadiness(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/settings/request/readiness`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_REQUEST_READINESS_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.CREATE_REQUEST_READINESS_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateRequestReadiness(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/settings/request/readiness`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.UPDATE_REQUEST_READINESS_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.UPDATE_REQUEST_READINESS_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* deleteRequestReadiness(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/settings/request/readiness`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.DELETE_REQUEST_READINESS_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.DELETE_REQUEST_READINESS_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

export default function* watchAction() {
  yield all([
    takeLatest(types.GET_COMPANY_PROFILE_ASYNC, getCompanyProfileAsync),
    takeLatest(types.UPDATE_COMPANY_PROFILE_ASYNC, updateCompanyProfileAsync),
    takeLatest(types.GET_EMPLOYEES_ASYNC, getEmployeesAsync),
    takeLatest(types.CREATE_EMPLOYEES_ASYNC, createEmployeesAsync),
    takeLatest(types.EDIT_EMPLOYEES_ASYNC, editEmployeesAsync),
    takeLatest(types.GET_LIST_ROLE_ASYNC, getListRoleAsync),
    takeLatest(types.CREATE_TEMPLATE_ASYNC, createTemplateAsync),
    takeLatest(types.UPDATE_TEMPLATE_ASYNC, updateTemplateAsync),
    takeLatest(types.GET_TEMPLATE_ASYNC, getListTemplateAsync),
    takeLatest(types.GET_TEMPLATE_DETAIL_ASYNC, getTemplateDetailAsync),
    takeLatest(types.GET_STATE_ASYNC, getListStateAsync),
    takeLatest(types.GET_CITY_BY_STATE_ASYNC, getCityByStateAsync),
    takeLatest(types.GET_ZIP_CODE_BY_STATE_AND_CITY_ASYNC, getZipCodeByStateAndCityAsync),
    takeLatest(types.GET_EMPLOYEES_DETAIL_ASYNC, getEmployeesDetailAsync),
    takeLatest(types.GET_ROLE_DETAIL_ASYNC, getRolesDetailAsync),
    takeLatest(types.EDIT_ROLE_ASYNC, editRoleAsync),
    takeLatest(types.CREATE_ROLE_ASYNC, createRoleAsync),
    takeLatest(types.GET_MEMBER_ROLE_ASYNC, getMemberRoleAsync),
    takeLatest(types.CREATE_MEMBER_ROLE_ASYNC, createMemberRoleAsync),
    takeLatest(types.DELETE_MEMBER_ROLE_ASYNC, deleteMemberRoleAsync),
    takeLatest(types.GET_ROLE_PERMISSiON_ASYNC, getPermissionRoleAsync),
    takeLatest(types.EDIT_ROLE_PERMISSiON_ASYNC, editPermissionRoleAsync),
    takeLatest(types.DELETE_ROLE_PERMISSiON_ASYNC, deletePermissionRoleAsync),
    takeLatest(types.GET_LIST_GROUP_ASYNC, getListGroupAsync),
    takeLatest(types.CREATE_GROUP_ASYNC, createGroupAsync),
    takeLatest(types.GET_GROUP_DETAIL_ASYNC, getGroupDetailAsync),
    takeLatest(types.EDIT_GROUP_ASYNC, editGroupAsync),
    takeLatest(types.GET_MEMBER_GROUP_ASYNC, getMemberGroupAsync),
    takeLatest(types.CREATE_MEMBER_GROUP_ASYNC, createMemberGroupAsync),
    takeLatest(types.DELETE_MEMBER_GROUP_ASYNC, deleteMemberGroupAsync),
    takeLatest(types.GET_COMPANY_METADATA_ASYNC, getCompanyMetaDataAsync),
    takeLatest(types.UPDATE_COMPANY_METADATA_ASYNC, updateCompanyMetaDataAsync),
    takeLatest(types.GET_REQUEST_CASE_METADATA_COMPANY_ASYNC, getRequestCaseMetaDatCompany),
    takeLatest(types.UPDATE_REQUEST_CASE_METADATA_COMPANY_ASYNC, updateRequestCaseMetaDatCompany),
    takeLatest(types.GET_REQUEST_READINESS, getRequestReadiness),
    takeLatest(types.CREATE_REQUEST_READINESS, createRequestReadiness),
    takeLatest(types.UPDATE_REQUEST_READINESS, updateRequestReadiness),
    takeLatest(types.DELETE_REQUEST_READINESS, deleteRequestReadiness)
  ])
}
