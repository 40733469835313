import {
  omit
} from 'lodash'
import * as types from './types'

const initialState = {
  isShowLoading: false,
  isShowSuccess: false,
  isShowError: false,
  isShowWarning: false,
  stateList: [],
  requestTypes: [],
  requestStatuses: [],
  requestResolutions: []
}

export default function common(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_LOADING: {
      if (action.loadingKey) {
        return {
          ...state,
          loadingKeys: {
            ...state.loadingKeys,
            [action.loadingKey]: true
          }
        }
      }
      return {
        ...state,
        isShowLoading: true
      }
    }

    case types.HIDE_LOADING: {
      if (action.loadingKey) {
        return {
          ...state,
          loadingKeys: {
            ...state.loadingKeys,
            [action.loadingKey]: false
          }
        }
      }
      return {
        ...state,
        isShowLoading: false
      }
    }

    case types.SHOW_POPUP_SUCCESS:
      return {
        ...state,
        isShowSuccess: true
      }
    case types.HIDE_POPUP_SUCCESS:
      return {
        ...state,
        isShowSuccess: false
      }

    case types.SHOW_POPUP_ERROR:
      return {
        ...state,
        isShowError: true,
        errorMsg: action.errorMsg
      }
    case types.HIDE_POPUP_ERROR:
      return {
        ...state,
        isShowError: false,
        errorMsg: null
      }

    case types.SHOW_POPUP_WARNING:
      return {
        ...state,
        isShowWarning: true,
        warningMsg: action.warningMsg
      }
    case types.HIDE_POPUP_WARNING:
      return {
        ...state,
        isShowWarning: false,
        warningMsg: null
      }

    case types.GET_STATE_SUCCESS:
      return {
        ...state,
        stateList: action.payload
      }

    case types.GET_REQUEST_TYPES_SUCCESS:
      return {
        ...state,
        requestTypes: action.payload.data
      }

    case types.GET_REQUEST_STATUSES_SUCCESS:
      return {
        ...state,
        requestStatuses: action.payload.data
      }

    case types.GET_REQUEST_RESOLUTIONS_SUCCESS:
      return {
        ...state,
        requestResolutions: action.payload.data.map((o) => {
          return Object.assign({
            value: o.id,
            label: o.name
          }, omit(o, 'id', 'name'))
        })
      }

    case 'LOG_OUT': {
      return {
        ...initialState
      }
    }

    default:
      return state
  }
}
