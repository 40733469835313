export const GET_COMPANY_PROFILE_ASYNC = 'GET_COMPANY_PROFILE_ASYNC'
export const GET_COMPANY_PROFILE_SUCCESS = 'GET_COMPANY_PROFILE_SUCCESS'
export const GET_COMPANY_PROFILE_FAIL = 'GET_COMPANY_PROFILE_FAIL'

export const UPDATE_COMPANY_PROFILE_ASYNC = 'UPDATE_COMPANY_PROFILE_ASYNC'
export const UPDATE_COMPANY_PROFILE_SUCCESS = 'UPDATE_COMPANY_PROFILE_SUCCESS'
export const UPDATE_COMPANY_PROFILE_FAIL = 'UPDATE_COMPANY_PROFILE_FAIL'

export const GET_EMPLOYEES_ASYNC = 'GET_EMPLOYEES_ASYNC'
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS'
export const GET_EMPLOYEES_FAIL = 'GET_EMPLOYEES_FAIL'

export const GET_EMPLOYEES_DETAIL_ASYNC = 'GET_EMPLOYEES_DETAIL_ASYNC'
export const GET_EMPLOYEES_DETAIL_SUCCESS = 'GET_EMPLOYEES_DETAIL_SUCCESS'
export const GET_EMPLOYEES_DETAIL_FAIL = 'GET_EMPLOYEES_DETAIL_FAIL'

export const CREATE_EMPLOYEES_ASYNC = 'CREATE_EMPLOYEES_ASYNC'
export const CREATE_EMPLOYEES_SUCCESS = 'CREATE_EMPLOYEES_SUCCESS'
export const CREATE_EMPLOYEES_FAIL = 'CREATE_EMPLOYEES_FAIL'

export const EDIT_EMPLOYEES_ASYNC = 'EDIT_EMPLOYEES_ASYNC'
export const EDIT_EMPLOYEES_SUCCESS = 'EDIT_EMPLOYEES_SUCCESS'
export const EDIT_EMPLOYEES_FAIL = 'EDIT_EMPLOYEES_FAIL'

export const GET_LIST_ROLE_ASYNC = 'GET_LIST_ROLE_ASYNC'
export const GET_LIST_ROLE_SUCCESS = 'GET_LIST_ROLE_SUCCESS'
export const GET_LIST_ROLE_FAIL = 'GET_LIST_ROLE_FAIL'

export const GET_ROLE_DETAIL_ASYNC = 'GET_ROLE_DETAIL_ASYNC'
export const GET_ROLE_DETAIL_SUCCESS = 'GET_ROLE_DETAIL_SUCCESS'
export const GET_ROLE_DETAIL_FAIL = 'GET_ROLE_DETAIL_FAIL'

export const EDIT_ROLE_ASYNC = 'EDIT_ROLE_ASYNC'
export const EDIT_ROLE_SUCCESS = 'EDIT_ROLE_SUCCESS'
export const EDIT_ROLE_FAIL = 'EDIT_ROLE_FAIL'

export const CREATE_ROLE_ASYNC = 'CREATE_ROLE_ASYNC'
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS'
export const CREATE_ROLE_FAIL = 'CREATE_ROLE_FAIL'

export const GET_MEMBER_ROLE_ASYNC = 'GET_MEMBER_ROLE_ASYNC'
export const GET_MEMBER_ROLE_SUCCESS = 'GET_MEMBER_ROLE_SUCCESS'
export const GET_MEMBER_ROLE_FAIL = 'GET_MEMBER_ROLE_FAIL'

export const CREATE_MEMBER_ROLE_ASYNC = 'CREATE_MEMBER_ROLE_ASYNC'
export const CREATE_MEMBER_ROLE_SUCCESS = 'CREATE_MEMBER_ROLE_SUCCESS'
export const CREATE_MEMBER_ROLE_FAIL = 'CREATE_MEMBER_ROLE_FAIL'

export const DELETE_MEMBER_ROLE_ASYNC = 'DELETE_MEMBER_ROLE_ASYNC'
export const DELETE_MEMBER_ROLE_SUCCESS = 'DELETE_MEMBER_ROLE_SUCCESS'
export const DELETE_MEMBER_ROLE_FAIL = 'GET_MEMBER_ROLE_FAIL'

export const CREATE_TEMPLATE_ASYNC = 'CREATE_TEMPLATE_ASYNC'
export const CREATE_TEMPLATE_SUCCESS = 'CREATE_TEMPLATE_SUCCESS'
export const CREATE_TEMPLATE_FAIL = 'CREATE_TEMPLATE_FAIL'

export const UPDATE_TEMPLATE_ASYNC = 'UPDATE_TEMPLATE_ASYNC'
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS'
export const UPDATE_TEMPLATE_FAIL = 'UPDATE_TEMPLATE_FAIL'

export const GET_TEMPLATE_ASYNC = 'GET_TEMPLATE_ASYNC'
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS'
export const GET_TEMPLATE_FAIL = 'GET_TEMPLATE_FAIL'

export const GET_TEMPLATE_DETAIL_ASYNC = 'GET_TEMPLATE_DETAIL_ASYNC'
export const GET_TEMPLATE_DETAIL_SUCCESS = 'GET_TEMPLATE_DETAIL_SUCCESS'
export const GET_TEMPLATE_DETAIL_FAIL = 'GET_TEMPLATE_DETAIL_FAIL'

export const GET_STATE_ASYNC = 'GET_STATE_ASYNC'
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS'
export const GET_STATE_FAIL = 'GET_STATE_FAIL'

export const GET_CITY_BY_STATE_ASYNC = 'GET_CITY_BY_STATE_ASYNC'
export const GET_CITY_BY_STATE_SUCCESS = 'GET_CITY_BY_STATE_SUCCESS'
export const GET_CITY_BY_STATE_FAIL = 'GET_CITY_BY_STATE_FAIL'

export const GET_ZIP_CODE_BY_STATE_AND_CITY_ASYNC = 'GET_ZIP_CODE_BY_STATE_AND_CITY_ASYNC'
export const GET_ZIP_CODE_BY_STATE_AND_CITY_SUCCESS = 'GET_ZIP_CODE_BY_STATE_AND_CITY_SUCCESS'
export const GET_ZIP_CODE_BY_STATE_AND_CITY_FAIL = 'GET_ZIP_CODE_BY_STATE_AND_CITY_FAIL'

export const GET_ROLE_PERMISSiON_ASYNC = 'GET_ROLE_PERMISSiON_ASYNC'
export const GET_ROLE_PERMISSiON_SUCCESS = 'GET_ROLE_PERMISSiON_SUCCESS'
export const GET_ROLE_PERMISSiON_FAIL = 'GET_ROLE_PERMISSiON_FAIL'

export const EDIT_ROLE_PERMISSiON_ASYNC = 'EDIT_ROLE_PERMISSiON_ASYNC'
export const EDIT_ROLE_PERMISSiON_SUCCESS = 'EDIT_ROLE_PERMISSiON_SUCCESS'
export const EDIT_ROLE_PERMISSiON_FAIL = 'EDIT_ROLE_PERMISSiON_FAIL'

export const DELETE_ROLE_PERMISSiON_ASYNC = 'DELETE_ROLE_PERMISSiON_ASYNC'
export const DELETE_ROLE_PERMISSiON_SUCCESS = 'DELETE_ROLE_PERMISSiON_SUCCESS'
export const DELETE_ROLE_PERMISSiON_FAIL = 'DELETE_ROLE_PERMISSiON_FAIL'

export const GET_LIST_GROUP_ASYNC = 'GET_LIST_GROUP_ASYNC'
export const GET_LIST_GROUP_SUCCESS = 'GET_LIST_GROUP_SUCCESS'
export const GET_LIST_GROUP_FAIL = 'GET_LIST_GROUP_FAIL'

export const CREATE_GROUP_ASYNC = 'CREATE_GROUP_ASYNC'
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS'
export const CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL'

export const GET_GROUP_DETAIL_ASYNC = 'GET_GROUP_DETAIL_ASYNC'
export const GET_GROUP_DETAIL_SUCCESS = 'GET_GROUP_DETAIL_SUCCESS'
export const GET_GROUP_DETAIL_FAIL = 'GET_GROUP_DETAIL_FAIL'

export const EDIT_GROUP_ASYNC = 'EDIT_GROUP_ASYNC'
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS'
export const EDIT_GROUP_FAIL = 'EDIT_GROUP_FAIL'

export const GET_MEMBER_GROUP_ASYNC = 'GET_MEMBER_GROUP_ASYNC'
export const GET_MEMBER_GROUP_SUCCESS = 'GET_MEMBER_GROUP_SUCCESS'
export const GET_MEMBER_GROUP_FAIL = 'GET_MEMBER_GROUP_FAIL'

export const CREATE_MEMBER_GROUP_ASYNC = 'CREATE_MEMBER_GROUP_ASYNC'
export const CREATE_MEMBER_GROUP_SUCCESS = 'CREATE_MEMBER_GROUP_SUCCESS'
export const CREATE_MEMBER_GROUP_FAIL = 'CREATE_MEMBER_GROUP_FAIL'

export const DELETE_MEMBER_GROUP_ASYNC = 'DELETE_MEMBER_GROUP_ASYNC'
export const DELETE_MEMBER_GROUP_SUCCESS = 'DELETE_MEMBER_GROUP_SUCCESS'
export const DELETE_MEMBER_GROUP_FAIL = 'GET_MEMBER_GROUP_FAIL'

export const GET_COMPANY_METADATA_ASYNC = 'GET_COMPANY_METADATA_ASYNC'
export const GET_COMPANY_METADATA_SUCCESS = 'GET_COMPANY_METADATA_SUCCESS'
export const GET_COMPANY_METADATA_FAIL = 'GET_COMPANY_METADATA_FAIL'

export const UPDATE_COMPANY_METADATA_ASYNC = 'UPDATE_COMPANY_METADATA_ASYNC'
export const UPDATE_COMPANY_METADATA_SUCCESS = 'UPDATE_COMPANY_METADATA_SUCCESS'
export const UPDATE_COMPANY_METADATA_FAIL = 'UPDATE_COMPANY_METADATA_FAIL'

export const GET_REQUEST_CASE_METADATA_COMPANY_ASYNC = 'GET_REQUEST_CASE_METADATA_COMPANY_ASYNC'
export const GET_REQUEST_CASE_METADATA_COMPANY_SUCCESS = 'GET_REQUEST_CASE_METADATA_COMPANY_SUCCESS'
export const GET_REQUEST_CASE_METADATA_COMPANY_FAIL = 'GET_REQUEST_CASE_METADATA_COMPANY_FAIL'

export const UPDATE_REQUEST_CASE_METADATA_COMPANY_ASYNC = 'UPDATE_REQUEST_CASE_METADATA_COMPANY_ASYNC'
export const UPDATE_REQUEST_CASE_METADATA_COMPANY_SUCCESS = 'UPDATE_REQUEST_CASE_METADATA_COMPANY_SUCCESS'
export const UPDATE_REQUEST_CASE_METADATA_COMPANY_FAIL = 'UPDATE_REQUEST_CASE_METADATA_COMPANY_FAIL'

export const GET_REQUEST_READINESS = 'GET_REQUEST_READINESS'
export const GET_REQUEST_READINESS_FAIL = 'GET_REQUEST_READINESS_FAIL'

export const CREATE_REQUEST_READINESS = 'CREATE_REQUEST_READINESS'
export const CREATE_REQUEST_READINESS_FAIL = 'CREATE_REQUEST_READINESS_FAIL'

export const UPDATE_REQUEST_READINESS = 'UPDATE_REQUEST_READINESS'
export const UPDATE_REQUEST_READINESS_FAIL = 'UPDATE_REQUEST_READINESS_FAIL'

export const DELETE_REQUEST_READINESS = 'DELETE_REQUEST_READINESS'
export const DELETE_REQUEST_READINESS_FAIL = 'DELETE_REQUEST_READINESS_FAIL'
