import 'regenerator-runtime/runtime' // eslint-disable-line
import { all } from 'redux-saga/effects'

import WatchAccounts from '../containers/accounts/sagas'
import WatchSettings from '../containers/settings/sagas'
import WatchPatients from '../containers/patientManage/sagas'
import WatchClients from '../containers/clientManage/sagas'
import WatchPartnerNetwork from '../containers/manageNetwork/sagas'
import WatchSubmitRequest from '../containers/submitRequest/sagas'
import WatchTrackRequest from '../containers/trackRequest/sagas'
import WatchCase from '../containers/manageCase/saga'
import WatchNotifications from '../containers/notifications/saga'
import WatchDashBoard from '../containers/dashboard/saga'
import WatchCommon from '../containers/common/sagas'
import WatchAutomation from '../containers/automation/sagas'

export default function* rootSagas() {
  yield all([
    WatchAccounts(),
    WatchSettings(),
    WatchPatients(),
    WatchClients(),
    WatchPartnerNetwork(),
    WatchSubmitRequest(),
    WatchTrackRequest(),
    WatchCase(),
    WatchNotifications(),
    WatchCommon(),
    WatchDashBoard(),
    WatchAutomation()
  ])
}
