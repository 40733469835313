export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'

export const SHOW_POPUP_SUCCESS = 'SHOW_POPUP_SUCCESS'
export const HIDE_POPUP_SUCCESS = 'HIDE_POPUP_SUCCESS'

export const SHOW_POPUP_ERROR = 'SHOW_POPUP_ERROR'
export const HIDE_POPUP_ERROR = 'HIDE_POPUP_ERROR'

export const SHOW_POPUP_WARNING = 'SHOW_POPUP_WARNING'
export const HIDE_POPUP_WARNING = 'HIDE_POPUP_WARNING'

export const GET_STATE_ASYNC = 'GET_STATE_ASYNC'
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS'
export const GET_STATE_FAIL = 'GET_STATE_FAIL'

export const UPLOAD_FILE_ASYNC = 'UPLOAD_FILE_ASYNC'

export const DELETE_FILE_ASYNC = 'DELETE_FILE_ASYNC'

export const EDIT_FILE_ASYNC = 'EDIT_FILE_ASYNC'
export const EDIT_FILE_SUCCESS = 'EDIT_FILE_SUCCESS'
export const EDIT_FILE_FAIL = 'EDIT_FILE_FAIL'

export const SEND_EMAIL_ASYNC = 'SEND_EMAIL_ASYNC'
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS'
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL'

export const GET_REQUEST_STATUSES_ASYNC = 'GET_REQUEST_STATUSES_ASYNC'
export const GET_REQUEST_STATUSES_SUCCESS = 'GET_REQUEST_STATUSES_SUCCESS'
export const GET_REQUEST_STATUSES_FAIL = 'GET_REQUEST_STATUSES_FAIL'

export const GET_REQUEST_RESOLUTIONS_ASYNC = 'GET_REQUEST_RESOLUTIONS_ASYNC'
export const GET_REQUEST_RESOLUTIONS_SUCCESS = 'GET_REQUEST_RESOLUTIONS_SUCCESS'
export const GET_REQUEST_RESOLUTIONS_FAIL = 'GET_REQUEST_RESOLUTIONS_FAIL'

export const GET_REQUEST_TYPES_ASYNC = 'GET_REQUEST_TYPES_ASYNC'
export const GET_REQUEST_TYPES_SUCCESS = 'GET_REQUEST_TYPES_SUCCESS'
export const GET_REQUEST_TYPES_FAIL = 'GET_REQUEST_TYPES_FAIL'
