import {
  call, takeLatest, all, put, takeEvery
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import {
  request, LOPRX_BASE_URL, version
} from '../../api'
import * as types from './types'
import { objectToParams } from '../../helpers/utils'

function* uploadFile(action) {
  try {
    const { status, data } = yield call(request.uploadFile, 'POST', `${LOPRX_BASE_URL}/${version}/business/files?labels=${action.fileType}`, action.payload.params)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* editFile(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/files/${action.fileId}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* deleteFile(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/files/${action.fileId}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* sendFileToEmail(action) {
  try {
    const { status, data, message } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/helpers`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, message)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getRequestTypesAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/request-types?${objectToParams(action.payload)}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_REQUEST_TYPES_SUCCESS, payload: { data } })
        break
      }

      default: {
        yield [
          put({ type: types.GET_REQUEST_TYPES_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_REQUEST_TYPES_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getRequestStatusesAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/request-statuses?${objectToParams(action.payload)}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_REQUEST_STATUSES_SUCCESS, payload: { data } })
        break
      }

      default: {
        yield [
          put({ type: types.GET_REQUEST_STATUSES_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_REQUEST_STATUSES_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getRequestResolutionsAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/request-resolutions?${objectToParams(action.payload)}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_REQUEST_RESOLUTIONS_SUCCESS, payload: { data } })
        break
      }

      default: {
        yield [
          put({ type: types.GET_REQUEST_RESOLUTIONS_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_REQUEST_RESOLUTIONS_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

export default function* watchAction() {
  yield all([
    takeEvery(types.UPLOAD_FILE_ASYNC, uploadFile),
    takeLatest(types.EDIT_FILE_ASYNC, editFile),
    takeLatest(types.DELETE_FILE_ASYNC, deleteFile),
    takeLatest(types.SEND_EMAIL_ASYNC, sendFileToEmail),
    takeLatest(types.GET_REQUEST_TYPES_ASYNC, getRequestTypesAsync),
    takeLatest(types.GET_REQUEST_STATUSES_ASYNC, getRequestStatusesAsync),
    takeLatest(types.GET_REQUEST_RESOLUTIONS_ASYNC, getRequestResolutionsAsync)
  ])
}
