import {
  call, put, takeLatest, all, takeEvery
} from 'redux-saga/effects'
import * as responseCode from '../../configs/responseCode.config'
import * as types from './types'
import {
  request, LOPRX_BASE_URL, version
} from '../../api'
import * as commonTypes from '../common/types'
import { objectToParams } from '../../helpers/utils'

function* getRequestListAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/requests?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_TRACK_REQUEST_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: types.GET_TRACK_REQUEST_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_TRACK_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getRequestReportAsync(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/requests?${objectToParams(action.payload.params)}`)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
          break
        }
        yield put({
          type: types.GET_TRACK_REQUEST_REPORT_SUCCESS,
          payload: {
            [action.payload.updateKey]: action.payload.params.returnType === 'count' ? data.total : data.meta.total
          }
        })
        break
      }

      default: {
        yield [
          put({ type: types.GET_TRACK_REQUEST_REPORT_FAIL })
        ]
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_TRACK_REQUEST_REPORT_FAIL })
    ]
  }
}

function* getRequestDetailAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.id}`)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: types.GET_TRACK_REQUEST_DETAIL_SUCCESS, payload: data }),
          put({ type: commonTypes.HIDE_LOADING })
        ]
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_TRACK_REQUEST_DETAIL_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_TRACK_REQUEST_DETAIL_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getRequestAttachmentsAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.id}/attachments`)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_TRACK_REQUEST_ATTACHMENTS_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_TRACK_REQUEST_ATTACHMENTS_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_TRACK_REQUEST_ATTACHMENTS_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateRequestDetailAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.id}`, action.payload.params)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: types.UPDATE_TRACK_REQUEST_SUCCESS, payload: data }),
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
  }
}

function* updateRequestAttachmentsAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.id}/attachments`, action.payload.params)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: types.GET_TRACK_REQUEST_ATTACHMENTS_ASYNC, payload: { id: action.payload.id } }),
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getDiscussionAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.requestId}/discussion`)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_DISCUSSION_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_DISCUSSION_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_DISCUSSION_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createDiscussionAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.params.id}/discussion`, action.payload.params)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.CREATE_DISCUSSION_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_DISCUSSION_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.CREATE_DISCUSSION_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createLetterAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.id}/letter`, action.payload.params)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getLetterContentAsync(action) {
  try {
    const {
      status, fileType, fileContent
    } = yield call(request.getFile, 'GET', `${LOPRX_BASE_URL}/${version}/business/letters/${action.payload.id}/content`)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, fileType, fileContent)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getAttachmentContentAsync(action) {
  try {
    const {
      status, fileType, fileContent
    } = yield call(request.getFile, 'GET', `${LOPRX_BASE_URL}/${version}/business/files/${action.payload.id}`)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, fileType, fileContent)
        }
        break
      }

      default: {
        if (action.callback) {
          action.callback(false)
        }
      }
    }
  } catch (error) {
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createOrderAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.id}/orders`, action.payload.params)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateOrderAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.id}/orders`, action.payload.params)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* deleteOrderAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.id}/orders`, action.payload.params)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getRequestListByCaseAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/cases/${action.payload.caseId}/requests`)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_TRACK_REQUEST_BY_CASE_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_TRACK_REQUEST_BY_CASE_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_TRACK_REQUEST_BY_CASE_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getRelatedRequestAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.id}/related-requests?${objectToParams(action.payload.params)}`)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_RELATED_REQUEST_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_RELATED_REQUEST_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_RELATED_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* addRelatedRequestAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.id}/related-requests`, action.payload.params)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* deleteRelatedRequestAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.id}/related-requests`, action.payload.params)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getHelperTemplateAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/helpers`, action.payload.params)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createActivityAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/requests/${action.id}/activities`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        yield put({ type: types.CREATE_ACTIVITY_REQUEST_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_ACTIVITY_REQUEST_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.CREATE_ACTIVITY_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getActivityAsync(action) {
  try {
    if (action.isHideLoading === true) {
      yield put({ type: commonTypes.HIDE_LOADING })
    } else {
      yield put({ type: commonTypes.SHOW_LOADING })
    }
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/requests/${action.id}/activities?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
        }
        yield put({ type: types.GET_ACTIVITY_REQUEST_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.GET_ACTIVITY_REQUEST_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_ACTIVITY_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* deleteActivityAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/requests/${action.id}/activities`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        yield put({ type: types.DELETE_ACTIVITY_REQUEST_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.DELETE_ACTIVITY_REQUEST_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.DELETE_ACTIVITY_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getOderHistoryAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/helpers`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_ORDER_HISTORY_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: types.GET_ORDER_HISTORY_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_ORDER_HISTORY_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateServiceChargeAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.id}/deals`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createServiceChargeAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/requests/${action.payload.id}/deals`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        yield put({ type: types.CREATE_ACTIVITY_REQUEST_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_ACTIVITY_REQUEST_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.CREATE_ACTIVITY_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getServiceChargeHistoryAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/helpers`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_SERVICE_CHARGE_HISTORY_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: types.GET_SERVICE_CHARGE_HISTORY_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_SERVICE_CHARGE_HISTORY_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getDatesRequestAsync(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/requests/${action.requestId}/metadata?metaKey=${action.metaKey}`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
        }
        yield put({ type: types.GET_DATES_REQUEST_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: types.GET_DATES_REQUEST_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_DATES_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getEndDatesRequestAsync(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/requests/${action.requestId}/metadata?metaKey=endDate`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
        }
        yield put({ type: types.GET_END_DATES_REQUEST_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: types.GET_END_DATES_REQUEST_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_END_DATES_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getReceiverRequestAsync(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/requests/${action.requestId}/metadata?metaKey=receiverCanCloseRequest`, action.payload)
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data, meta)
        }
        yield put({ type: types.GET_RECEIVER_REQUEST_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: types.GET_RECEIVER_REQUEST_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: types.GET_RECEIVER_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateRequestMetaData(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/requests/${action.requestId}/metadata`, action.payload.params)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateReceiverRequestAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/requests/${action.requestId}/metadata`, action.payload.params)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: data.message })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: commonTypes.SHOW_POPUP_ERROR, errorMsg: error.data.message })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createAssignRequestAsync(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/requests/${action.id}/assign`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        yield put({ type: types.CREATE_ASSIGN_REQUEST_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.CREATE_ASSIGN_REQUEST_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.CREATE_ASSIGN_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* deleteAssignRequestAsync(action) {
  try {
    yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/requests/${action.id}/assign`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        yield put({ type: types.DELETE_ASSIGN_REQUEST_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.DELETE_ASSIGN_REQUEST_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.DELETE_ASSIGN_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* deleteLetterRequestAsync(action) {
  try {
    // yield put({ type: commonTypes.SHOW_LOADING })
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/requests/${action.requestId}/letter`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield [
          put({ type: commonTypes.SHOW_POPUP_SUCCESS })
        ]
        yield put({ type: types.DELETE_LETTER_REQUEST_SUCCESS, payload: data })
        break
      }

      default: {
        yield [
          put({ type: commonTypes.HIDE_LOADING }),
          put({ type: types.DELETE_LETTER_REQUEST_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.DELETE_LETTER_REQUEST_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* getCaseReadiness(action) {
  try {
    const { status, data, meta } = yield call(request.callAPI, 'GET', `${LOPRX_BASE_URL}/${version}/business/${action.option}/${action.id}/readiness?${objectToParams(action.payload)}`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.GET_READINESS_ACTION_SUCCESS, payload: { data, meta } })
        break
      }

      default: {
        yield [
          put({ type: types.GET_READINESS_ACTION_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.GET_READINESS_ACTION_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* createCaseReadiness(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'POST', `${LOPRX_BASE_URL}/${version}/business/requests/${action.id}/readiness`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.CREATE_READINESS_ACTION_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: types.CREATE_READINESS_ACTION_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.CREATE_READINESS_ACTION_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* updateCaseReadiness(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'PUT', `${LOPRX_BASE_URL}/${version}/business/requests/${action.id}/readiness`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.UPDATE_READINESS_ACTION_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: types.UPDATE_READINESS_ACTION_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.UPDATE_READINESS_ACTION_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

function* deleteCaseReadiness(action) {
  try {
    const { status, data } = yield call(request.callAPI, 'DELETE', `${LOPRX_BASE_URL}/${version}/business/requests/${action.id}/readiness`, action.payload)
    yield put({ type: commonTypes.HIDE_LOADING })
    switch (status) {
      case responseCode.REQUEST_SUCCESS: {
        if (action.callback) {
          action.callback(true, data)
        }
        yield put({ type: types.DELETE_READINESS_ACTION_SUCCESS })
        break
      }

      default: {
        yield [
          put({ type: types.DELETE_READINESS_ACTION_FAIL })
        ]
        if (action.callback) {
          action.callback(false, data)
        }
      }
    }
  } catch (error) {
    yield [
      put({ type: commonTypes.HIDE_LOADING }),
      put({ type: types.DELETE_READINESS_ACTION_FAIL })
    ]
    if (action.callback) {
      action.callback(false, error)
    }
  }
}

export default function* watchAction() {
  yield all([
    takeLatest(types.GET_TRACK_REQUEST_ASYNC, getRequestListAsync),
    takeLatest(types.GET_TRACK_REQUEST_DETAIL_ASYNC, getRequestDetailAsync),
    takeLatest(types.GET_DISCUSSION_ASYNC, getDiscussionAsync),
    takeEvery(types.CREATE_DISCUSSION_ASYNC, createDiscussionAsync),
    takeLatest(types.UPDATE_TRACK_REQUEST_ASYNC, updateRequestDetailAsync),
    takeLatest(types.GET_LETTER_CONTENT_ASYNC, getLetterContentAsync),
    takeLatest(types.GET_ATTACHMENT_CONTENT_ASYNC, getAttachmentContentAsync),
    takeLatest(types.CREATE_LETTER_ASYNC, createLetterAsync),
    takeLatest(types.GET_TRACK_REQUEST_BY_CASE_ASYNC, getRequestListByCaseAsync),
    takeLatest(types.GET_TRACK_REQUEST_ATTACHMENTS_ASYNC, getRequestAttachmentsAsync),
    takeLatest(types.UPDATE_TRACK_REQUEST_ATTACHMENTS_ASYNC, updateRequestAttachmentsAsync),
    takeLatest(types.CREATE_ORDER_ASYNC, createOrderAsync),
    takeLatest(types.UPDATE_ORDER_ASYNC, updateOrderAsync),
    takeLatest(types.DELETE_ORDER_ASYNC, deleteOrderAsync),
    takeLatest(types.GET_RELATED_REQUEST_ASYNC, getRelatedRequestAsync),
    takeLatest(types.ADD_RELATED_REQUEST_ASYNC, addRelatedRequestAsync),
    takeLatest(types.DELETE_RELATED_REQUEST_ASYNC, deleteRelatedRequestAsync),
    takeLatest(types.GET_HELPER_TEMPLATE_ASYNC, getHelperTemplateAsync),
    takeEvery(types.GET_TRACK_REQUEST_REPORT_ASYNC, getRequestReportAsync),
    takeLatest(types.GET_ACTIVITY_REQUEST_ASYNC, getActivityAsync),
    takeLatest(types.DELETE_ACTIVITY_REQUEST_ASYNC, deleteActivityAsync),
    takeLatest(types.CREATE_ACTIVITY_REQUEST_ASYNC, createActivityAsync),
    takeLatest(types.GET_ORDER_HISTORY_ASYNC, getOderHistoryAsync),
    takeLatest(types.UPDATE_SERVICE_CHARGE_ASYNC, updateServiceChargeAsync),
    takeLatest(types.CREATE_SERVICE_CHARGE_ASYNC, createServiceChargeAsync),
    takeLatest(types.GET_SERVICE_CHARGE_HISTORY_ASYNC, getServiceChargeHistoryAsync),
    takeLatest(types.GET_REQUEST_METADATA, getDatesRequestAsync),
    takeLatest(types.GET_END_DATES_REQUEST_ASYNC, getEndDatesRequestAsync),
    takeLatest(types.UPDATE_REQUEST_METADATA_ASYNC, updateRequestMetaData),
    takeLatest(types.UPDATE_RECEIVER_REQUEST_ASYNC, updateReceiverRequestAsync),
    takeLatest(types.GET_RECEIVER_REQUEST_ASYNC, getReceiverRequestAsync),
    takeLatest(types.CREATE_ASSIGN_REQUEST_ASYNC, createAssignRequestAsync),
    takeLatest(types.DELETE_ASSIGN_REQUEST_ASYNC, deleteAssignRequestAsync),
    takeLatest(types.DELETE_LETTER_REQUEST_ASYNC, deleteLetterRequestAsync),
    takeEvery(types.GET_READINESS_ACTION, getCaseReadiness),
    takeLatest(types.CREATE_READINESS_ACTION, createCaseReadiness),
    takeLatest(types.UPDATE_READINESS_ACTION, updateCaseReadiness),
    takeLatest(types.DELETE_READINESS_ACTION, deleteCaseReadiness)
  ])
}
