import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Footer from './footer'
import Header from './header'
import Sidebar from './sidebar'
import Loading from '../loading'
import Notification from '../notifications'
import * as AccountTypes from '../../containers/accounts/types'
import * as CommonTypes from '../../containers/common/types'
import { urlStringToParams } from '../../helpers/utils'

class PrivateLayout extends Component {
  static propTypes = {
    history: PropTypes.object,
    children: PropTypes.any,
    notificationList: PropTypes.object,
    dispatch: PropTypes.func
    // path: PropTypes.string
  }

  constructor(prop) {
    super(prop)
    this.state = {
      isLoading: true,
      iShowSideBar: false
    }

    this.onToggleLeftSideBar = this.onToggleLeftSideBar.bind(this)
    this.onToggleTopBar = this.onToggleTopBar.bind(this)
    this.getUserProfileCallback = this.getUserProfileCallback.bind(this)
  }

  componentDidMount() {
    const url_string = window.location.href
    const url = new URL(url_string)
    const {
      userInfo, dispatch, location, history
    } = this.props

    if (userInfo.accessToken) {
      dispatch({
        type: CommonTypes.GET_REQUEST_TYPES_ASYNC
      })
      dispatch({
        type: CommonTypes.GET_REQUEST_STATUSES_ASYNC
      })
      // dispatch({
      //   type: CommonTypes.GET_REQUEST_RESOLUTIONS_ASYNC
      // })
      dispatch({
        type: AccountTypes.GET_USER_PROFILE_ASYNC,
        callback: this.getUserProfileCallback
      })
    } else {
      let redirectUrl = url.pathname + url.search
      dispatch({
        type: AccountTypes.GET_URL_BEFORE_SIGN_ASYNC,
        url: redirectUrl
      })
      let redirectParams = urlStringToParams(redirectUrl)
      // if redirectUrl have param emailAddress (haven't account), redirect to Sign Up
      history.push({
        pathname: (redirectParams.emailAddress || redirectParams.emailaddress) ? '/sign-up' : '/sign-in',
        state: { from: location }
      })
    }
  }

  getUserProfileCallback(status) {
    const { history, location } = this.props
    if (status) {
      this.setState({ isLoading: false }) // eslint-disable-line
    } else {
      history.push({
        pathname: '/sign-up',
        state: { from: location }
      })
    }
  }

  onToggleLeftSideBar() {
    if (document.querySelector('body').classList.contains('m-aside-left--on')) {
      document.querySelector('body').classList.remove('m-aside-left--on')
      document.querySelector('#m_aside_left').classList.remove('m-aside-left--on')
      this.setState({ iShowSideBar: false })
    } else {
      document.querySelector('body').classList.add('m-aside-left--on')
      document.querySelector('#m_aside_left').classList.add('m-aside-left--on')
      this.setState({ iShowSideBar: true })
    }
  }

  onToggleTopBar() {
    if (document.querySelector('body').classList.contains('m-topbar--on')) {
      document.querySelector('body').classList.remove('m-topbar--on')
    } else {
      document.querySelector('body').classList.add('m-topbar--on')
    }
  }

  render() {
    const {
      history, children, dispatch, userInfo, notificationList, location
    } = this.props
    const { isLoading, iShowSideBar } = this.state
    if (isLoading) {
      return (
        <div className="m-grid m-grid--hor m-grid--root m-page">
          <Loading forceShowLoading />
        </div>
      )
    }
    return (
      <div className="m-grid m-grid--hor m-grid--root m-page">
        {/* <Notification dispatch={dispatch} /> */}
        {/* <Sidebar userInfo={userInfo} history={history} location={history.location} onToggleLeftSideBar={this.onToggleLeftSideBar} /> */}
        <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body" style={{ paddingLeft: 0 }}>
          <Loading />
          <Header history={history} dispatch={dispatch} userInfo={userInfo} notificationList={notificationList} onToggleTopBar={this.onToggleTopBar} onToggleLeftSideBar={this.onToggleLeftSideBar} />
          <button onClick={this.onToggleLeftSideBar} className="m-aside-left-close m-aside-left-close--skin-dark" type="button">
            <i className="fas fa-times" />
          </button>
          {iShowSideBar && <div className="m-aside-left-overlay" onClick={this.onToggleLeftSideBar} />} {/* eslint-disable-line */}
          {children}
        </div>
        {
          !['/', '/privacy-policy-and-terms'].includes(location.pathname) && <Footer />
        }

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    userInfo: state.accountsReducer.userInfo,
    notificationList: state.notificationReducer.notificationList
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateLayout)
