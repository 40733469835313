import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { checkPermissions } from '../../helpers/utils'

class Sidebar extends Component {
  static propTypes = {
    userInfo: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
    }
    this.checkRouter = this.checkRouter.bind(this)
  }

  onClickMenuItem = () => () => {
    const { onToggleLeftSideBar } = this.props
    onToggleLeftSideBar()
  }

  checkRouter(pathName) {
    const { location } = this.props
    if (location.pathname.indexOf(pathName) >= 0) {
      return true
    }
    return false
  }

  render() {
    const { userInfo } = this.props
    return (
      /* <!-- BEGIN: Left Aside --> */
      <div>
        <div id="m_aside_left" className="m-grid__item m-aside-left  m-aside-left--skin-dark attorney-bg">
          <div id="m_ver_menu" className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark m-aside-menu--dropdown">
            <ul className="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
              <li className={`m-menu__item color-hover ${this.checkRouter('/track-request') ? 'm-menu__item--active' : ''}`} data-menu-submenu-toggle="hover" key="track">
                <NavLink onClick={this.onClickMenuItem('/track-request')} to="/track-request" activeClassName="m-menu__link m-menu__toggle" className="m-menu__link m-menu__toggle color-toggle">
                  <span className="m-menu__item-here" />
                  <i className="m-menu__link-icon fas fa-file-alt icon-sidebar-attorney" />
                  <span className="m-menu__link-text text-sidebar-attorney">Track Request</span>
                  <i className="m-menu__ver-arrow fas fa-angle-right" />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
function mapStateToProp(state) {
  return {
    userInfo: state.accountsReducer.userInfo
  }
}

export default connect(mapStateToProp, null)(Sidebar)
