import { combineReducers } from 'redux'
import { i18nState } from 'redux-i18n'
import accountsReducer from '../containers/accounts/reducers'
import commonReducer from '../containers/common/reducers'
import settingsReducer from '../containers/settings/reducers'
import patientReducer from '../containers/patientManage/reducers'
import clientReducer from '../containers/clientManage/reducers'
import partnerReducer from '../containers/partnerManage/reducers'
import networkReducer from '../containers/manageNetwork/reducers'
import trackRequestReducer from '../containers/trackRequest/reducers'
import caseReducer from '../containers/manageCase/reducers'
import notificationReducer from '../containers/notifications/reducers'
import submitRequestReducer from '../containers/submitRequest/reducers'
import dashboardReducer from '../containers/dashboard/reducers'
import caseAutomationReducer from '../containers/automation/reducers'

const rootReducer = combineReducers({
  accountsReducer,
  commonReducer,
  settingsReducer,
  clientReducer,
  patientReducer,
  networkReducer,
  trackRequestReducer,
  caseReducer,
  notificationReducer,
  submitRequestReducer,
  i18nState,
  partnerReducer,
  dashboardReducer,
  caseAutomationReducer
})

export default rootReducer
