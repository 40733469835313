import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import { socketIO } from '../../api'
import { calculatorDatetime, formatDate } from '../../helpers/utils'
import * as notificationTypes from '../../containers/notifications/types'
import ImageThumbnail from '../imageThumbnail'
import * as AccountTypes from '../../containers/accounts/types'

class Header extends Component {
  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func,
    userInfo: PropTypes.object,
    notificationList: PropTypes.object
  }

  constructor(prop) {
    super(prop)
    this.state = {
    }

    this.onClickLogOut = this.onClickLogOut.bind(this)
    this.subscribeNotification = this.subscribeNotification.bind(this)
    this.appendNotifications = this.appendNotifications.bind(this)
    this.updateNotify = this.updateNotify.bind(this)
    this.getNotifications = this.getNotifications.bind(this)
    this.getProfile = this.getProfile.bind(this)
    this.onClickSeeAllNotifications = this.onClickSeeAllNotifications.bind(this)
  }

  componentDidMount() {
    // this.getNotifications()
  }

  onClickLogOut() {
    const { dispatch } = this.props
    dispatch({ type: 'LOG_OUT' })
    setTimeout(() => {
      socketIO.disconnect(null, () => { window.location.href = '/sign-in' })
    }, 500)
  }

  getNotifications() {
    socketIO.socketIOGet('/v1/business/notifications', this.subscribeNotification)
  }

  getProfile() {
    const { dispatch, history } = this.props
    dispatch({
      type: AccountTypes.GET_USER_PROFILE_ASYNC,
      callback: (status, data) => {
        let link = '/profile'
        if (status) {
          history.push(link)
        } else {
          return Swal.fire({
            type: 'error',
            title: `${data.data.message}`
          })
        }
      }
    })
  }

  subscribeNotification(data) {
    const { userInfo, dispatch } = this.props
    if (data) {
      dispatch({
        type: notificationTypes.GET_NOTIFICATIONS_SUCCESS,
        payload: { data: data.data, meta: data.meta }
      })
    }
    socketIO.subscribe(`notification-${userInfo.id}`, this.appendNotifications)
  }

  appendNotifications(response) {
    const { dispatch, notificationList: { data } } = this.props
    let mgs = { notificationList: { data } }
    let audio = new Audio('/assets/img/ding.wav')
    if (mgs) {
      audio.play()
    }
    dispatch({
      type: notificationTypes.APPEND_NOTIFICATIONS_SUCCESS,
      payload: {
        data: uniqBy([response.data].concat(data), 'id'),
        meta: response.meta
      }
    })
  }

  updateNotify(id) {
    const { dispatch } = this.props
    dispatch({
      type: notificationTypes.UPDATE_NOTIFICATIONS_ASYNC,
      payload: {
        id
      },
      callback: this.getNotifications
    })
  }

  onClickNotify = notify => (e) => {
    e.preventDefault()
    const { history, onToggleTopBar } = this.props
    if (!notify.isRead) {
      this.updateNotify(notify.id)
    }
    history.push({
      pathname: `${notify.targetUrl}`,
      state: { notify, time: new Date() }
    })
    window.location.reload()
    if (window.screen.width <= 768) {
      onToggleTopBar()
      $('.m-dropdown__dropoff').click()
    }
  }

  onClickSeeAllNotifications() {
    const { history } = this.props
    $('.m-topbar__notifications').click()
    history.push('/notifications')
  }

  render() {
    const {
      userInfo, notificationList: { data, meta }, onToggleLeftSideBar
    } = this.props

    return (
      <header className="row m-grid__item m-header" style={{ boxShadow: 'rgb(190 190 190) 20px 20px 20px, rgb(255 255 254) -20px -20px 20px' }}>
        <div className="col-12 d-inline-flex align-items-center">
          <Link
            to="/track-request"
            onClick={() => window.gtag('event', 'Click on track request', { event_category: 'Hamburger Bar', non_interaction: true })}
            style={{
              color: '#000', marginLeft: '1em', display: 'flex', alignItems: 'center', textDecoration: 'none'
            }}
          >
            <i className="fas fa-bars" style={{ color: '#fd7e14', fontSize: '2em', marginRight: '0.25em' }}></i> Track Request
          </Link>
          <Dropdown className="" style={{ marginRight: '1em', marginLeft: 'auto' }}>
            <Dropdown.Toggle variant="." bsPrefix="." className="bg-white">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '0.85em',
                  color: '#aeafc2'
                }}
              >
                <span
                  className="m-topbar__userinfo"
                  style={{
                    display: 'inline-flex',
                    flexDirection: 'column',
                    marginRight: '10px',
                    textAlign: 'right'
                  }}
                >
                  <span>{`${userInfo.firstName} ${userInfo.lastName}`}</span>
                  <span>{userInfo.emailAddress}</span>
                </span>
                <span className="m-topbar__userpic">
                  <ImageThumbnail product={window.portal} image={isEmpty(userInfo.avatar) ? '/assets/img/user3.png' : userInfo.avatar} width="40" height="40" />
                </span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0" style={{ width: '300px' }}>
              <div className="p-0">
                <div className="m-dropdown__inner bg-white">
                  <div className="m-dropdown__header m--align-center m--padding-20" id="user_profile_icon">
                    <div className="m-card-user m-card-user--skin-dark">
                      <div className="m-card-user__pic">
                        <ImageThumbnail product={window.portal} image={isEmpty(userInfo.avatar) ? '/assets/img/user3.png' : userInfo.avatar} />
                      </div>
                      <div className="m-card-user__details">
                        <span className="m-card-user__name m--font-weight-500 name-profile">
                          {userInfo.firstName}
                          {' '}
                          {userInfo.lastName}
                        </span>
                        <p className="name-email m--font-weight-300">
                          {userInfo.emailAddress}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="m-dropdown__body m--padding-20">
                    <div className="m-dropdown__content">
                      <ul className="m-nav m-nav--skin-light">
                        <li className="m-nav__section m--hide">
                          <span className="m-nav__section-text">Section</span>
                        </li>
                        <li className="m-nav__item">
                          <Link to="/profile" className="m-nav__link">
                            <i className="m-nav__link-icon fas fa-user-circle" />
                            <span className="m-nav__link-title">
                              <span className="m-nav__link-wrap">
                                <span className="m-nav__link-text">My Profile</span>
                              </span>
                            </span>
                          </Link>
                        </li>
                        <li className="m-nav__item">
                          <Link to="/profile?active=change-password" className="m-nav__link">
                            <i className="m-nav__link-icon fas fa-lock" />
                            <span className="m-nav__link-title">
                              <span className="m-nav__link-wrap">
                                <span className="m-nav__link-text">Update Password</span>
                              </span>
                            </span>
                          </Link>
                        </li>
                        <li className="m-nav__separator m-nav__separator--fit" />
                        <li className="m-nav__item">
                          <div onClick={this.onClickLogOut} onKeyDown={this.onClickLogOut} className="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder">Logout</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
    )
  }
}

function mapStateToProp(state) {
  return {
    userInfo: state.accountsReducer.userInfo
  }
}

export default connect(mapStateToProp, null)(Header)
