import * as types from './types'

const initialState = {
  userMetadata: {
    quickReportDisplayed: false
  },
  userInfo: {},
  forgetPassword: {},
  getUrl: ''
}

export default function accounts(state = initialState, action) {
  switch (action.type) {
    case types.SIGN_UP_SUCCESS:
      return {
        ...state,
        userInfo: action.payload
      }

    case types.SIGN_IN_SUCCESS:
      localStorage.setItem('user-status', 'connected')
      return {
        ...state,
        userInfo: action.payload
      }

    case types.SIGN_IN_VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        userInfo: action.payload
      }

    case types.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        forgetPassword: action.payload
      }

    case types.GET_USER_PROFILE_SUCCESS:
    case types.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload
        }
      }
    case types.SET_ACCESS_TOKEN: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload
        }
      }
    }
    case types.LOG_OUT: {
      localStorage.setItem('user-status', '')
      return {
        ...initialState
      }
    }
    case types.GET_URL_BEFORE_SIGN_SUCCESS:
      return {
        ...state,
        getUrl: action.payload
      }
    case types.SET_USER_METADATA_SUCCESS:
      return {
        ...state,
        userMetadata: {
          ...state.userMetadata,
          [action.metaKey]: action.metaValue
        }
      }
    default:
      return state
  }
}
